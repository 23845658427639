import React from "react";
import "./Maintenance.css"; // Create a CSS file for styling
import maintenance from "./images/gif-maintances.gif"

const Maintenance = () => {
  return (
    <div className="maintenance-container">
      <img src={maintenance} alt="Website Under Construction" className="logo"  />
      <h1>Website Under Construction</h1>
      <p>We are currently making improvements. Please check back later.</p>
    </div>
  );
};

export default Maintenance;
