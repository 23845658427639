import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Footer.css";
import FooterArrow from "../images/footer-arrow.png";
import { baseURL } from "../../services/Aut.post.js";
import { getAllResultDetails } from "../../services/Aut.post.js";
import TermsandConditions from "../TermsandConditions";

function Footer() {
  const [footertext, setFooterText] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Get the navigate function

  const [showModal, setShowModal] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const imageClasses = `rotate ${isVisible ? "rotated" : ""} cursor-pointer`;

  useEffect(() => {
    const getFooterText = async () => {
      const headers = {
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.get(`${baseURL}/userapi/getsitetext/`, {
          headers,
        });
        setFooterText(response.data.GetFtext);
        return response.data;
      } catch (error) {
        console.error("Error fetching user account status:", error);
        throw error;
      }
    };
    getFooterText();
  }, []);

  const handleClick = (path) => (e) => {
    e.preventDefault(); // Prevent default behavior

    if (location.pathname === "/Gamep") {
      // Check if current path is /Gamep
      const confirmNavigation = window.confirm(
        "Are you sure you want to exit the game?"
      );

      if (confirmNavigation) {
        navigate(path); // Navigate to the clicked page if confirmed
      }
      // If canceled, do nothing and stay on the same page
    } else {
      navigate(path); // Navigate to the clicked page if not on /Gamep
    }
  };

  const updateSubscriptions = () => {
    if (location.pathname === "/Gamep") {
      // Check if current path is /Gamep
      const confirmNavigation = window.confirm(
        "Are you sure you want to exit the game?"
      );

      if (!confirmNavigation) {
        return; // If canceled, stop execution
      }
    }

    // API for site token verification
    const fetchData = async () => {
      try {
        const ssoURL = "https://tockens.com";
        const ssoRedirectUrl = "https://bigbonanza.in";
        const RedirectTo = "https://tockens.com/MainSubScriptionFeatures"; // Updated redirect

        const data = await getAllResultDetails("getsitesettings", "TOCKENSKEY");
        console.log(data.setting_value); // Log the data

        if (data?.setting_value) {
          const subscription_link = `${ssoRedirectUrl}/identifier?next=${encodeURIComponent(
            ssoURL
          )}/identity&redirectto=${encodeURIComponent(
            RedirectTo
          )}&stocken=${encodeURIComponent(data.setting_value)}`;

          // Open the constructed URL in a new tab
          window.open(subscription_link, "_blank");
        } else {
          console.error("Some Error Found:");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };

  return (
    <div className="relative-1">
      <div className="footer-bg">
        <div className="FooterArrow">
          <img
            src={FooterArrow}
            alt="Toggle Image"
            onClick={toggleVisibility}
            className={imageClasses}
          />
        </div>

        {isVisible && (
          <div className="footerlinks">
            <ul>
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    updateSubscriptions();
                  }}
                >
                  Subscriptions
                </a>
              </li>
              <li>
                <Link to="/AboutUs" onClick={handleClick("/AboutUs")}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/ContactUs" onClick={handleClick("/ContactUs")}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/PrivacyPolicy"
                  onClick={handleClick("/PrivacyPolicy")}
                >
                  Privacy Policy
                </Link>
              </li>

              <li>
                {/* <Link to="/TermsandConditions" onClick={handleClick('/TermsandConditions')}>Terms and Conditions</Link> */}
                <p className="link-button" onClick={() => setShowModal(true)}>
                  Terms and Conditions
                </p>
              </li>
            </ul>
          </div>
        )}

        <div>{footertext}</div>
      </div>
      {/* Terms & Conditions Popup */}
      {showModal && (
        <div className="modal-overlay-footer">
          <div className="modal-content-footer">
            <button
              className="close-button-footer"
              onClick={() => setShowModal(false)}
            >
              ✖
            </button>
            <div className="terms-content">
              <TermsandConditions />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
